<template>
  <div class="animated fadeIn">
    <h4 class="mb-3">
      Пригласить пользователя
    </h4>
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col md="6">
          <b-form-group
            class="form-group-el-select"
            label="Пользователь"
            label-for="user"
          >
            <el-select
              id="user"
              v-model="form.adUser"
              class="form-control"
              name="roles"
              placeholder="Начните вводить данные пользователя AD, не менее 2-х символов"
              clearable
              :filterable="true"
              :remote="true"
              :remote-method="fetchOptions"
              :loading="loading"
              @change="onChange"
            >
              <el-option
                v-for="item in users"
                :key="item.value.username"
                :label="item.text"
                :value="item.value"
              />
            </el-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            class="form-group-el-select"
            label="Роль"
            label-for="roles"
          >
            <el-select-clearable
              id="roles"
              v-model="form.roles"
              class="form-control"
              name="roles"
              placeholder="Выберите одну или несколько ролей"
              clearable
              :multiple="true"
              :loading="loading"
            >
              <el-option
                v-for="item in roles"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              />
            </el-select-clearable>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          :class="{'loading' : loading}"
          :disabled="loading"
        >
          Сохранить
        </b-button>
      </div>
    </b-form>
    <customer-users-roles-info-table/>
  </div>
</template>

<script>
import ElSelectClearable from '../../components/ElSelectClearable.vue'
import notifications from '../../components/mixins/notifications';
import {customersAdUsersList, customersAdUserCreate} from '../../services/api';
import vSelect from 'vue-select';
import CustomerUsersRolesInfoTable from "@/views/CustomerUsers/CustomerUsersRolesInfoTable";

export default {
  name: 'CustomerUserInvite',
  components: {CustomerUsersRolesInfoTable, vSelect, ElSelectClearable},
  mixins: [notifications],
  props: ['contactId'],
  data() {
    return {
      loading: false,
      data: {},
      form: {
        roles: [],
        adUser: null,
      },
      roles: [
        {value: 'ROLE_ADMIN', text: 'Администратор'},
        {value: 'ROLE_MANAGER', text: 'Менеджер'},
        {value: 'ROLE_VIEW', text: 'Просмотр'},
      ],
      users: [],
    };
  },
  mounted() {
    this.fetchOptions('');
  },
  methods: {
    async fetchOptions(query) {
      this.loading = true;
      const response = await customersAdUsersList(this.$store.state.user.ownerId, {
        query: query,
        limit: 100,
      });
      if (response && response.status === 200) {
        this.users = response.data.items.map((item) => {
          return {value: item.username, text: item.displayName + ' (' + item.email + ')', email: item.email, fullname: item.displayName};
        });
      }
      this.loading = false;
    },
    onChange(value) {
      this.users.map((user) => {
        if (user.value === value) {
          this.data.username = user.value;
          this.data.fullname = user.fullname;
          this.data.email = user.email;
        }
      });
    },
    async onSubmit() {
      const sendData = this.data;
      if (this.form.roles.length) {
        sendData.roles = this.form.roles;
        this.loading = true;
        const response = await customersAdUserCreate(this.$store.state.user.ownerId, sendData);
        if (response && response.status === 201) {
          this.showSuccess('Пользователь ' + this.data.email + ' добавлен');
        }
        this.loading = false;
      } else {
        delete sendData.roles;
        this.showWarn('Выберите как минимум одну роль');
      }
    },
  },
};
</script>

